import { Box, Divider, Modal, Typography } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './TableRowDataModal.css'

function TableRowDataModal({ table_data, open, close }) {

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                // onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal'>
                    <CloseIcon className='close-icon' onClick={close}/>
                    <div className='modal-content-right'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {table_data.version}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            Trade Promotion
                        </Typography>
                        <div style={{padding:"10px 0"}}>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Owner :</p>
                                <h3>&nbsp;{table_data.owner}</h3>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Volume :</p>
                                <h3>&nbsp;{table_data.volume}</h3>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Trade Spending: :</p>
                                <h3>&nbsp;{table_data.trade_spend}</h3>
                            </div>
                        </div>
                    </div>
                    <Divider orientation='vertical' className='modal-divider' />
                    <div className='modal-content-left'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                            {table_data.version}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            Trade Promotion
                        </Typography>
                        <div style={{padding:"10px 0"}}>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Owner :</p>
                                <h3>&nbsp;{table_data.owner}</h3>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Volume :</p>
                                <h3>&nbsp;{table_data.volume}</h3>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}><p style={{ color: "#9FA2B4" }}>Trade Spending: :</p>
                                <h3>&nbsp;{table_data.trade_spend}</h3>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

export default TableRowDataModal