import React, { useState } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import './Plans.css'
import { Divider } from '@material-ui/core';
import ScenarioAdmin from './Scenario-admin/Scenario-admin';
import StrategicPricing from './Strategic-pricing/Strategic-pricing';
import Innovation from './Innovation/Innovation';


function Plans() {
  const [user_name] = useState('')
  const [active_header, setActiveHeader] = useState('Scenario Admin');
  const [headers] = useState(['Scenario Admin', 'Strategic Pricing', 'Trade Promotion', 'Consumer Promotion', 'Media', 'Assortment & Distribution', 'Innovation']);
  

  const handleChnage = (header) => {
    setActiveHeader(header)
  }

  const renderContent = () => {
    switch (active_header) {
      case headers[0]:
        return <ScenarioAdmin />
      case headers[1]:
        return <StrategicPricing />
      case headers[2]:
        return <StrategicPricing />
      case headers[3]:
        return <StrategicPricing />
      case headers[4]:
        return <StrategicPricing />
      case headers[5]:
        return <StrategicPricing />
      case headers[6]:
        return <Innovation />
    }

  }

  return <div className='plans-page'>
    <div className='primary-header' style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
      <h1 className='header'>Strategic Commercial Plans</h1>
      <div className='user-data'>
        <div className='date-time'>
          <CalendarMonthIcon />
          <span style={{ fontFamily: "monospace" }}>&nbsp; Wednesday, 17 May 2021</span>
        </div>
        <div className='search-bell'>
          <SearchIcon style={{ color: '#C5C7CD' }} />
          <NotificationsIcon style={{ color: '#C5C7CD' }} />
        </div>
        <Divider orientation='vertical' />
        <div className='user-name-picture' style={{ display: "flex", alignItems: "center" }}>
          <h3 className='user-name' style={{ marginRight: "5px" }}>{user_name}</h3>
          <AccountCircleIcon />
        </div>
      </div>
    </div>
    <div className='navigation-header'>
      {headers.map((h, i) => {
        return <div key={i} onClick={() => handleChnage(h)} className={active_header === h ? 'headers active-header' : 'headers'}>
          <h2 style={{ margin: "auto", maxWidth: "80%" }} className='header-headings'>{h}</h2>
        </div>
      })}
    </div>
    {renderContent()}
  </div>
  // </div>
}

export default Plans