import { Divider, FormControl, MenuItem,ListItemText, makeStyles, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import { CONFIG } from '../../helpers/config';
import './key-financials.css';
import { abbreviateNumber } from '../../helpers/utils';



const useStyles = makeStyles((theme) => ({
  paperContainer: { overflowX: "auto" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180
  },
  menu: {
    width: 180,
    height:35,
    background: '#EDE8FF',
    borderRadius: "12px",
    "& ul": {
      backgroundColor: "#cccccc",
  },
  "& li": {
      fontSize: 12,
  },
  },
  menuHistory: {
    width: 220,
    height:35,
    background: '#FFF2E9',
    borderRadius: "12px"
  },
  settingFilter: {
    background:"#FFFFFF",
    border: '1px solid #E3E6EB',
    borderRadius: '40px',
  },
  listItem: {
    cursor: "pointer",
    fortFamily:'momospace',
    fontSize:'medium',
    whiteSpace: "normal",
  },
  root: {
    borderBottom: "none"
  }

}));

function KeyFinancials() {

  const classes = useStyles()

  const handleChange = (event) => {
    SetBuFilter(event.target.value)
  }

  const [user_name, setUserName] = useState('')
  const [BU_filter_options, SetBuFilterOptions] = useState(['Cookies', 'Food', 'Salty', 'Others']);
  const [BU_filter, SetBuFilter] = useState('Cookies');
  const [table_data, setTableData] = useState(CONFIG.SUMMARY_TABLE_DATA_FOR_KEY_FINANCIALS)
  const BU_filter_optionsValues = CONFIG.BU_filter_optionsValues;
  const BU_filter_optionsValues2 = CONFIG.BU_filter_optionsValues2;
  const BU_filter_optionsValues3 = CONFIG.BU_filter_optionsValues3;
  const BU_filter_optionsValues4 = CONFIG.BU_filter_optionsValues4;
  const BU_filter_optionsValues5 = CONFIG.BU_filter_optionsValues5;
  const BU_filter_optionsValues6 = CONFIG.BU_filter_optionsValues6;
  const BU_filter_optionsValues7= CONFIG.BU_filter_optionsValues7;
  const BU_filter_optionsValues8=CONFIG.BU_filter_optionsValues8;
  const BU_filter_optionsValues9=CONFIG.BU_filter_optionsValues9;

  const table_data_index = table_data.findIndex(t_d=>t_d.bu_filter===BU_filter)
  return <div className='summaries'>
    <div className='primary-header' style={{ display: "flex", justifyContent: "space-between",alignItems:'center' }}>
      <h1 className='header'>Year Summaries</h1>
      <div className='user-data'>
        <div className='date-time'>
          <CalendarMonthIcon />
          <span style={{fontFamily:"monospace"}}>&nbsp; Wednesday, 17 May 2021</span>
        </div>
        <div className='search-bell'>
          <SearchIcon style={{ color: '#C5C7CD' }} />
          <NotificationsIcon style={{ color: '#C5C7CD' }} />
        </div>
        <Divider orientation='vertical' />
        <h3 className='user-name'>{user_name}</h3>
        <AccountCircleIcon />
      </div>
    </div>
    <div className='setting-filters'>
    <FormControl variant="standard" className={classes.formControl}>
        
        </FormControl>
    </div>
    <div className='header-row'>
      <div className='filters'>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={BU_filter}
            placeholder="BU"
            disableUnderline
            className={classes.menu}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {BU_filter_options.map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={"HSF"}
            placeholder="MFT"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['HSF','National Accounts','WM'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues2[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Bodega Aurerra'}
            placeholder="Customer"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Bodega Aurerra','Calimax','Casa Ley'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues3[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Algeria'}
            placeholder="Brand MKT"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Algeria','Bokados','Brownie','Choco Bara'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues4[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Algeria'}
            placeholder="Brand"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Algeria','Bokados','Brownie','Choco Bara'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues4[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'AB Bar Chocolate Display Mex'}
            placeholder="Type HILO"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['AB Bar Chocolate Display Mex','AB Bar Chocolate Granel Mex','AB Bar Chocolate Fresa Mex'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText style={{maxWidth:'90%',overflow:"hidden",textOverflow:'ellipsis'}} primary={BU_filter_optionsValues6[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Conveniencia'}
            placeholder="Channel"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Conveniencia','DSD','DSDD'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues7[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'BN Garnola Maple'}
            placeholder="Material DESC"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['BN Garnola Maple','Bolca Mini Zuc 60x20gm'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues8[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl><FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'12 oz'}
            placeholder="Cluster"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['12 oz','18 oz','24 oz'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues5[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl><FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'A-Regular'}
            placeholder="Velocity"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['A-Regular','B-Regular','C-Regular','History'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl><FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={1000067}
            placeholder="Material"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {[1000067,1345677].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl><FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'ASR'}
            placeholder="Customer Model"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['ASR','Comex','DSD'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues9[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Alexia'}
            placeholder="Demand Planner"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Alexia','Blanca','Elvia'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
      <div className='summary-history'>
        <h4 className='summary-history-heading'>
          History
        </h4>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={BU_filter}
            placeholder="BU"
            disableUnderline
            className={classes.menuHistory}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {BU_filter_options.map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <h4 className='summary-history-heading'>
          Outlooks
        </h4>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={BU_filter}
            placeholder="BU"
            disableUnderline
            className={classes.menuHistory}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {BU_filter_options.map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    </div>
    <div className='summary_table'>
      {table_data_index!==-1 && table_data[table_data_index].table_content.map((data, i) => {
        return <div key={`${i}${data.headers}`}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: "none", textAlign: "left", fontWeight: '700', fontSize: '19px', fontFamily: 'Mulish' }}>{data.header||null}</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                {data.data.columns.map((col, j) => {
                  return <TableCell style={{ fontWeight: "bold", borderBottom: "none", textAlign: "left", fontFamily: 'Mulish' }} key={`${j}${col}`}>{j === 0 ? <div style={{ display: "flex" }}><h4 style={{ fontWeight: "lighter", color: '#9FA2B4' }}>Summary:&nbsp;</h4><h4 style={{ fontWeight: "bolder" }}>{`${data.Summary}`}</h4></div> : col.slice(0,3)}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.values.map((val, m) => {
                return <TableRow key={`${m}`}>
                  {val.map((v, k) => {
                    return <TableCell style={{ textAlign: "left", fontWeight: k === 0 ? 'bold' : 'lighter', color: k !== 0 ? '#9FA2B4' : '#252733', fontFamily: 'Mulish' }} key={`${k}${v}`}>{k===0|| typeof v !== 'number'?v:abbreviateNumber(v)}</TableCell>
                  })}
                </TableRow>
              })}
              <TableRow>
                {data.data.impact && data.data.impact.map((im, l) => {
                  return <TableCell key={`${l}${im}`} style={{ textAlign: "left", borderBottom: "none", color: Number(im) > 0 ? 'rgb(0 255 4 / 87%)' : 'rgb(248 15 15 / 87%)', fontFamily: 'Mulish' }}>{im === null ? null : `(${im})`}</TableCell>
                })}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      })}
    </div>
  </div>
}

export default KeyFinancials