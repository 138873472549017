export const IMAGECONFIG = {
    NAVIGATION:{
        LOGO:require('../images/Logo.png'),
        PLANSLOGO:require('../images/PlansLogo.png'),
        PLANSLOGOACTIVE:require('../images/PlansLogoActive.png'),
        SUMMARYLOGO:require('../images/SummaryLogo.png'),
        SUMMARYLOGOACTIVE:require('../images/SummaryLogoActive.png'),
        CATEGORYSUMMARYLOGO:require('../images/CategorySummaryLogo.png'),
        CATEGORYSUMMARYLOGOACTIVE:require('../images/CategorySummaryLogoActive.png'),
        KEYACCOUNTSLOGO:require('../images/KeyAccountsLogo.png'),
        KEYACCOUNTSLOGOACTIVE:require('../images/KeyAccountsLogoActive.png'),
        FINANCIALSUMMARYLOGO:require('../images/FinancialSummaryLogo.png'),
        FINANCIALSUMMARYLOGOACTIVE:require('../images/FinancialSummaryLogoActive.png'),
        GLOSSARYLOGO:require('../images/GlossaryLogo.png'),
        SETTINGSLOGO:require('../images/SettingsLogo.png'),
    }
}