import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";
import "./Innovation.css";
import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highcharts.src.js";
import HighchartsReact from "highcharts-react-official";
import SquareIcon from "@mui/icons-material/Square";
import HighMaps from "highcharts/modules/map";
import usMap from "@highcharts/map-collection/countries/us/us-all.geo.json";
import { CONFIG } from "../../../helpers/config";

HighMaps(Highcharts);

const useStyles = makeStyles((theme) => ({
  paperContainer: { overflowX: "auto" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180
  },
  formControl_1: {
    margin: theme.spacing(1),
    minWidth: 130
  },
  menu: {
    width: 180,
    height: 35,
    background: "#EDE8FF",
    borderRadius: "12px",
    "& ul": {
      backgroundColor: "#cccccc"
    },
    "& li": {
      fontSize: 12
    }
  },
  menuHistory: {
    width: 130,
    height: 35,
    background: "#FFF2E9",
    borderRadius: "12px"
  },
  settingFilter: {
    background: "#FFFFFF",
    border: "1px solid #E3E6EB",
    borderRadius: "40px"
  },
  listItem: {
    cursor: "pointer",
    fortFamily: "momospace",
    fontSize: "medium",
    whiteSpace: "normal"
  },
  root: {
    borderBottom: "none"
  }
}));

function Innovation() {
  const classes = useStyles();

  const [table_data, setTableData] = useState(CONFIG.INNOVATION_TABLE);
  const [country_chart_options, setCountryChartOptions] = useState([]);
  const BU_filter_optionsValues2 = CONFIG.BU_filter_optionsValues2;
  const BU_filter_optionsValues3 = CONFIG.BU_filter_optionsValues3;
  const BU_filter_optionsValues4 = CONFIG.BU_filter_optionsValues4;
  const BU_filter_optionsValues5 = CONFIG.BU_filter_optionsValues5;

  useEffect(() => {
    updateCountryChartOptions();
  }, []);

  const countryChartConfig = () => {
    let chart_config_options = [];

    chart_config_options.push({
      chart: {
        map: usMap,
        borderWidth: 0,
        marginTop: 80,
        marginLeft: -100
      },
      credits: {
        enabled: false
      },
      title: {
        text: "Launch Status",
        align: "left",
        x: 0,
        verticalAlign: "top",
        y: 20,
        margin: 0,
        style: CONFIG.CHART_TITLE_STYLE
      },
      subtitle: {
        text: "as of 15 July 2022, 09:41 PM",
        align: "left",
        x: 0,
        verticalAlign: "top"
        // y: 0,
      },

      exporting: {
        sourceWidth: 600,
        sourceHeight: 500
      },

      legend: {
        enabled: false
      },

      mapNavigation: {
        enabled: false
      },

      colorAxis: {
        min: 1,
        type: "logarithmic",
        minColor: "#ECECEC",
        maxColor: "#D01D2A",
        stops: [
          [0, "#ECECEC"],
          [0.67, "#F48384"],
          [1, "#D01D2A"]
        ]
      },

      series: [
        {
          accessibility: {
            point: {
              valueDescriptionFormat: "{xDescription}, {point.value}"
            }
          },
          animation: {
            duration: 1000
          },
          data: CONFIG.USA_MAP_GROWTH_DATA,
          joinBy: ["postal-code", "code"],
          dataLabels: {
            enabled: false
          },
          name: "Adoption Rate",
          tooltip: {
            pointFormat: "{point.code}: {point.value}%"
          }
        }
      ]
    });

    return chart_config_options;
  };

  const updateCountryChartOptions = async () => {
    let chart_options = await countryChartConfig();
    console.log("chart_options", chart_options);
    setCountryChartOptions(chart_options);
  };

  return (
    <>
      <div className="header-row">
        <div className="filters">
          <h4 className="summary-history-heading">Filters</h4>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"HSF"}
              placeholder="MFT"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["HSF", "National Accounts", "WM"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues2[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Bodega Aurerra"}
              placeholder="Customer"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Bodega Aurerra", "Calimax", "Casa Ley"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues3[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Algeria"}
              placeholder="Brand"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Algeria", "Bokados", "Brownie", "Choco Bara"].map(
                (filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={BU_filter_optionsValues4[filter]} />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"12 oz"}
              placeholder="Cluster"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["12 oz", "18 oz", "24 oz"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues5[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="summary-history">
          <h4 className="summary-history-heading">Time Period</h4>
          <div className="dropdown-flex">
            <FormControl variant="standard" className={classes.formControl_1}>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={"Monthly"}
                placeholder="Time Period"
                disableUnderline
                className={classes.menuHistory}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  },
                  getContentAnchorEl: null
                }}
              >
                {["Monthly", "Quarterly"].map((filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={filter} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="standard" className={classes.formControl_1}>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={"Quarterly"}
                placeholder="Time Period"
                disableUnderline
                className={classes.menuHistory}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  },
                  getContentAnchorEl: null
                }}
              >
                {["Monthly", "Quarterly"].map((filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={filter} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {table_data.map((data, i) => {
        return (
          <div className="summary_table">
            <TableContainer className={classes.paperContainer}>
              <Table key={`${i}${data.headers}`}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "19px",
                        padding: "15px",
                        fontFamily: "Mulish"
                      }}
                    >
                      {data.header}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    {data.data.columns.map((col, j) => {
                      return (
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            borderBottom: "none",
                            textAlign: j === 0 ? "left" : "center",
                            padding: "15px",
                            fontFamily: "Mulish"
                          }}
                          key={`${j}${col}`}
                        >
                          {j === 0 ? (
                            <div
                              style={{ display: "flex", fontFamily: "Mulish" }}
                            >
                              <h4
                                style={{
                                  fontWeight: "lighter",
                                  color: "#9FA2B4",
                                  fontFamily: "Mulish"
                                }}
                              >
                                Summary:&nbsp;
                              </h4>
                              <h4
                                style={{
                                  fontWeight: "bolder",
                                  fontFamily: "Mulish"
                                }}
                              >{`${data.Summary}`}</h4>
                            </div>
                          ) : (
                            col
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data.values.map((val, m) => {
                    return (
                      <TableRow key={`${m}`}>
                        {val.map((v, k) => {
                          return (
                            <TableCell
                              style={{
                                textAlign: k === 0 ? "left" : "center",
                                padding: "15px",
                                fontWeight: k === 0 ? "bold" : "lighter",
                                color: k !== 0 ? "#9FA2B4" : "#252733",
                                fontFamily: "Mulish"
                              }}
                              key={`${k}${v}`}
                            >
                              {v}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {data.data.total ? (
                    <TableRow>
                      {data.data.total.map((im, l) => {
                        return (
                          <TableCell
                            style={{
                              textAlign: l === 0 ? "left" : "center",
                              fontFamily: "Mulish",
                              padding: "15px",
                              borderBottom: "none",
                              background: "#FFF0F5",
                              opacity: 0.7
                            }}
                            key={`${l}${im}`}
                          >
                            <div
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold"
                              }}
                            >
                              {im}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : (
                    <TableRow style={{ padding: "15px", borderBottom: "none" }}>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
      <div className="innovation-chart">
        <div className="innovation-map-chart">
          {country_chart_options.map((chart_option, i) => {
            return (
              <div key={i} className="highcharts-figure">
                <div id="react-container">
                  <HighchartsReact
                    constructorType={"mapChart"}
                    highcharts={Highcharts}
                    options={chart_option}
                    allowChartUpdate={true}
                    //updateArgs={[true, true, true]}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="chart-data">
          <TableContainer className="innovation-table-container">
            <Table>
              <TableHead>
                <TableRow style={{ background: "#E6ECF0" }}>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "left",
                      fontWeight: "700",
                      fontSize: "19px",
                      padding: "15px",
                      fontFamily: "Mulish"
                    }}
                  >
                    State
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "right",
                      fontWeight: "700",
                      fontSize: "19px",
                      padding: "15px",
                      fontFamily: "Mulish"
                    }}
                  >
                    Adoption Rate
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CONFIG.USA_MAP_GROWTH_DATA.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          textAlign: "left",
                          padding: "15px",
                          fontFamily: "Mulish"
                        }}
                      >
                        {data.State} ({data.code})
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          textAlign: "right",
                          padding: "15px",
                          fontFamily: "Mulish"
                        }}
                      >
                        {data.value}%
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Innovation;
