import {
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import "./Summary.css";
import { CONFIG } from "../../helpers/config";
import { abbreviateNumber } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  paperContainer: { overflowX: "auto" },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 180
  },
  menu: {
    width: 180,
    height: 35,
    background: "#EDE8FF",
    borderRadius: "12px",
    "& ul": {
      backgroundColor: "#cccccc"
    },
    "& li": {
      fontSize: 12
    }
  },
  menuHistory: {
    width: 220,
    height: 35,
    background: "#FFF2E9",
    borderRadius: "12px"
  },
  settingFilter: {
    background: "#FFFFFF",
    border: "1px solid #E3E6EB",
    borderRadius: "40px"
  },
  listItem: {
    cursor: "pointer",
    fortFamily: "momospace",
    fontSize: "medium",
    whiteSpace: "normal"
  },
  root: {
    borderBottom: "none"
  }
}));

function Summary() {
  const classes = useStyles();
  const [user_name, setUserName] = useState("");
  const [BU_filter_options, SetBuFilterOptions] = useState([
    "Cookies",
    "Food",
    "Salty",
    "Others"
  ]);
  const BU_filter_optionsValues = CONFIG.BU_filter_optionsValues;
  const BU_filter_optionsValues2 = CONFIG.BU_filter_optionsValues2;
  const BU_filter_optionsValues3 = CONFIG.BU_filter_optionsValues3;
  const BU_filter_optionsValues4 = CONFIG.BU_filter_optionsValues4;
  const BU_filter_optionsValues5 = CONFIG.BU_filter_optionsValues5;
  const BU_filter_optionsValues6 = CONFIG.BU_filter_optionsValues6;
  const BU_filter_optionsValues7= CONFIG.BU_filter_optionsValues7;
  const BU_filter_optionsValues8=CONFIG.BU_filter_optionsValues8;
  const BU_filter_optionsValues9=CONFIG.BU_filter_optionsValues9;
  const [BU_filter, SetBuFilter] = useState("Cookies");
  const [table_data, setTableData] = useState(CONFIG.SUMMARY_TABLE_DATA);
  const [combo_chart_options, setComboChartOptions] = useState([]);
  const [stacked_column_bar_chart_options, setStackedColumnChartOptions] =
    useState([]);

  const handleChange = (event) => {
    SetBuFilter(event.target.value);
  };

  useEffect(() => {
    updateChartConfig();
  }, [BU_filter]);

  const table_data_index = table_data.findIndex(
    (t_d) => t_d.bu_filter === BU_filter
  );

  const comboChartData = (index) => {
    let data = [];
    let combo_chart_index = table_data[
      table_data_index
    ].table_content.findIndex((t_c) => t_c.header === "Demand Planning");
    table_data[table_data_index].table_content[combo_chart_index].data.values[
      index
    ].forEach((val, i) => {
      if (i !== 0) {
        data.push(val);
      }
    });
    return data;
  };

  const comboChartDataForImpact = () => {
    let data = [];
    let combo_chart_index = table_data[
      table_data_index
    ].table_content.findIndex((t_c) => t_c.header === "Demand Planning");
    table_data[table_data_index].table_content[
      combo_chart_index
    ].data.impact.forEach((val, i) => {
      if (i !== 0) {
        data.push(Number(val));
      }
    });
    return data;
  };

  const comboChartSeries = () => {
    let series = [];
    let combo_chart_index = table_data[
      table_data_index
    ].table_content.findIndex((t_c) => t_c.header === "Demand Planning");

    table_data[table_data_index].table_content[
      combo_chart_index
    ].data.values.forEach((val, i) => {
      series.push({
        name: table_data[table_data_index].table_content[combo_chart_index].data
          .values[i][0],
        type: "column",
        stack: 1,
        yAxis: 1,
        color: CONFIG.SEIRES_COLOR[i],
        data: comboChartData(i)
      });
    });

    series.push({
      name:
        table_data[table_data_index].table_content[combo_chart_index].data
          .impact[0] === null
          ? "Growth"
          : null,
      type: "spline",
      color: CONFIG.SEIRES_COLOR[CONFIG.SEIRES_COLOR.length - 1],
      data: comboChartDataForImpact()
    });
    return series;
  };

  const comboChartConfig = (categories) => {
    let chart_config_options = [];

    chart_config_options.push({
      chart: {
        zoomType: "xy",
        inverted: false
      },
      title: {
        text: "vs Version",
        align: "left",
        x: 0,
        verticalAlign: "top",
        y: 20,
        margin: 0,
        style: CONFIG.CHART_TITLE_STYLE
      },
      subtitle: {
        text: "as of 15 July 2021, 09:41 PM",
        align: "left",
        x: 0,
        verticalAlign: "top"
        // y: 0,
      },
      xAxis: [
        {
          categories: categories,
          crosshair: true,
          lineColor: "transparent",
          lineWidth: 0
        }
      ],
      yAxis: [
        {
          // Primary yAxis
          visible: false
        },
        {
          // Secondary yAxis
          title: {
            text: "",
            style: {
              color: "#EBEDF0"
            }
          },
          labels: {
            format: "{value}",
            style: {
              color: "#9FA2B4"
            }
          },
          opposite: true
        }
      ],
      tooltip: {
        shared: true
      },
      // tooltip: chartTooltip(_, abbreviation || this.state.abbreviation, formatting || this.state.formatting),
      // legend: chartLegend(metric_count, template_config, table_data),
      legend: {
        layout: "horizontal",
        align: "right",
        x: 0,
        verticalAlign: "top",
        y: -30,
        floating: false,
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          "#FFFFFF"
      },
      plotOptions: {
        column: {
          stacking: "normal"
        },
        series: {
          borderRadius: 5
        }
      },
      // plotOptions: getPlotOptions(chart_config, _, _, abbreviation || this.state.abbreviation, formatting || this.state.formatting),
      series: comboChartSeries()
    });
    return chart_config_options;
  };

  const stackedColumnChartData = (index) => {
    let data = [];
    let stacked_column_chart_index = table_data[
      table_data_index
    ].table_content.findIndex((t_c) => t_c.header === "Building Blocks");
    table_data[table_data_index].table_content[
      stacked_column_chart_index
    ].data.values[index].forEach((val, i) => {
      if (i !== 0) {
        data.push(val);
      }
    });
    return data;
  };

  const stackedColumnSeries = () => {
    let series = [];
    let stacked_column_chart_index = table_data[
      table_data_index
    ].table_content.findIndex((t_c) => t_c.header === "Building Blocks");

    table_data[table_data_index].table_content[
      stacked_column_chart_index
    ].data.values?.forEach((val, i) => {
      series.push({
        name: table_data[table_data_index].table_content[
          stacked_column_chart_index
        ].data.values[i][0],
        data: stackedColumnChartData(i),
        color: CONFIG.SEIRES_COLOR[i]
      });
    });
    return series;
  };

  const stackedColumnChartConfig = (categories) => {
    let chart_config_options = [];

    chart_config_options.push({
      chart: {
        type: "column",
        zoom: "xy"
      },
      title: {
        text: "Building Blocks",
        align: "left",
        x: 0,
        verticalAlign: "top",
        y: 20,
        margin: 0,
        style: CONFIG.CHART_TITLE_STYLE
      },
      subtitle: {
        text: "as of 15 July 2021, 09:41 PM",
        align: "left",
        x: 0,
        verticalAlign: "top"
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        min: 0,
        opposite: true,
        title: {
          text: ""
        },
        stackLabels: {
          style: CONFIG.DATALABEL_STYLE
        },
        style: {
          color: "#EBEDF0"
        }
      },
      legend: {
        layout: "horizontal",
        align: "right",
        x: 0,
        verticalAlign: "top",
        y: -10,
        floating: false,
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          "#FFFFFF"
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
      },
      plotOptions: {
        column: {
          stacking: "normal"
        },
        series: {
          borderRadius: 5
        }
      },
      series: stackedColumnSeries()
    });
    return chart_config_options;
  };

  const updateChartConfig = () => {
    if (table_data_index !== -1) {
      let combo_chart_index = table_data[
        table_data_index
      ].table_content.findIndex((t_c) => t_c.header === "Demand Planning");
      let stacked_column_bar_chart_index = table_data[
        table_data_index
      ].table_content.findIndex((t_c) => t_c.header === "Building Blocks");
      let categories = [];
      let stacked_col_categories = [];
      if (combo_chart_index !== -1) {
        table_data[table_data_index].table_content[
          combo_chart_index
        ].data.columns.forEach((col, i) => {
          if (i !== 0) {
            categories.push(col.slice(0, 3));
          }
        });
      }

      if (stacked_column_bar_chart_index !== -1) {
        table_data[table_data_index].table_content[
          stacked_column_bar_chart_index
        ].data.columns.forEach((col, i) => {
          if (i !== 0) {
            stacked_col_categories.push(col.slice(0, 3));
          }
        });
      }
      console.log(categories, stacked_col_categories);
      let chart_options = comboChartConfig(categories);
      let stacked_column_chart_options = stackedColumnChartConfig(
        stacked_col_categories
      );
      setComboChartOptions(chart_options);
      setStackedColumnChartOptions(stacked_column_chart_options);
    }
  };

  return (
    <div className="summaries">
      <div
        className="primary-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h1 className="header">Year Summaries</h1>
        <div className="user-data">
          <div className="date-time">
            <CalendarMonthIcon />
            <span style={{ fontFamily: "monospace" }}>
              &nbsp; Wednesday, 17 May 2021
            </span>
          </div>
          <div className="search-bell">
            <SearchIcon style={{ color: "#C5C7CD" }} />
            <NotificationsIcon style={{ color: "#C5C7CD" }} />
          </div>
          <Divider orientation="vertical" />
          <h3 className="user-name">{user_name}</h3>
          <AccountCircleIcon />
        </div>
      </div>
      <div className="setting-filters">
        <FormControl
          variant="standard"
          className={classes.formControl}
        ></FormControl>
      </div>
      <div className="header-row">
        <div className="filters">
          <h4 className="summary-history-heading">Filters</h4>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={BU_filter}
              placeholder="BU"
              disableUnderline
              className={classes.menu}
              onChange={handleChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {BU_filter_options.map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"HSF"}
              placeholder="MFT"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["HSF", "National Accounts", "WM"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues2[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Bodega Aurerra"}
              placeholder="Customer"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Bodega Aurerra", "Calimax", "Casa Ley"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues3[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Algeria"}
              placeholder="Brand MKT"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Algeria", "Bokados", "Brownie", "Choco Bara"].map(
                (filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText
                        primary={BU_filter_optionsValues4[filter]}
                      />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Algeria"}
              placeholder="Brand"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Algeria", "Bokados", "Brownie", "Choco Bara"].map(
                (filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={BU_filter_optionsValues4[filter]} />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"AB Bar Chocolate Display Mex"}
              placeholder="Type HILO"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {[
                "AB Bar Chocolate Display Mex",
                "AB Bar Chocolate Granel Mex",
                "AB Bar Chocolate Fresa Mex"
              ].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText
                      style={{
                        maxWidth: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                      primary={BU_filter_optionsValues6[filter]}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Conveniencia"}
              placeholder="Channel"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Conveniencia", "DSD", "DSDD"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues7[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"BN Garnola Maple"}
              placeholder="Material DESC"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["BN Garnola Maple", "Bolca Mini Zuc 60x20gm"].map(
                (filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={BU_filter_optionsValues8[filter]} />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"12 oz"}
              placeholder="Cluster"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["12 oz", "18 oz", "24 oz"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues5[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"A-Regular"}
              placeholder="Velocity"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["A-Regular", "B-Regular", "C-Regular", "History"].map(
                (filter, i) => {
                  return (
                    <MenuItem
                      className={classes.listItem}
                      key={i}
                      value={filter}
                    >
                      <ListItemText primary={filter} />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={1000067}
              placeholder="Material"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {[1000067, 1345677].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={filter} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"ASR"}
              placeholder="Customer Model"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["ASR", "Comex", "DSD"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={BU_filter_optionsValues9[filter]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={"Alexia"}
              placeholder="Demand Planner"
              disableUnderline
              className={classes.menu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {["Alexia", "Blanca", "Elvia"].map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={filter} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="summary-history">
          <h4 className="summary-history-heading">History</h4>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={BU_filter}
              placeholder="BU"
              disableUnderline
              className={classes.menuHistory}
              onChange={handleChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {BU_filter_options.map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={filter} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <h4 className="summary-history-heading">Outlooks</h4>
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={BU_filter}
              placeholder="BU"
              disableUnderline
              className={classes.menuHistory}
              onChange={handleChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                },
                getContentAnchorEl: null
              }}
            >
              {BU_filter_options.map((filter, i) => {
                return (
                  <MenuItem className={classes.listItem} key={i} value={filter}>
                    <ListItemText primary={filter} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="summary_table">
        <TableContainer className={classes.paperContainer}>
          {table_data_index !== -1 &&
            table_data[table_data_index].table_content.map((data, i) => {
              return (
                <Table key={`${i}${data.headers}`}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: "19px",
                          padding: "15px",
                          fontFamily: "Mulish"
                        }}
                      >
                        {data.header}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      {data.data.columns.map((col, j) => {
                        return (
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              borderBottom: "none",
                              textAlign: "left",
                              padding: "15px",
                              fontFamily: "Mulish"
                            }}
                            key={`${j}${col}`}
                          >
                            {j === 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  fontFamily: "Mulish"
                                }}
                              >
                                <h4
                                  style={{
                                    fontWeight: "lighter",
                                    color: "#9FA2B4",
                                    fontFamily: "Mulish"
                                  }}
                                >
                                  Summary:&nbsp;
                                </h4>
                                <h4
                                  style={{
                                    fontWeight: "bolder",
                                    fontFamily: "Mulish"
                                  }}
                                >{`${data.Summary}`}</h4>
                              </div>
                            ) : (
                              col.slice(0, 3)
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.values.map((val, m) => {
                      return (
                        <TableRow key={`${m}`}>
                          {val.map((v, k) => {
                            return (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  padding: "15px",
                                  fontWeight: k === 0 ? "bold" : "lighter",
                                  color: k !== 0 ? "#9FA2B4" : "#252733",
                                  fontFamily: "Mulish"
                                }}
                                key={`${k}${v}`}
                              >
                                {k === 0 ? v : abbreviateNumber(v)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      {data.data.impact &&
                        data.data.impact.map((im, l) => {
                          return (
                            <TableCell
                              key={`${l}${im}`}
                              style={{
                                textAlign: "left",
                                fontFamily: "Mulish",
                                padding: "15px",
                                borderBottom: "none",
                                color:
                                  Number(im) > 0
                                    ? "rgb(0 255 4 / 87%)"
                                    : "rgb(248 15 15 / 87%)"
                              }}
                            >
                              {im === null ? null : `(${im.toFixed(2)})`}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableBody>
                </Table>
              );
            })}
        </TableContainer>
      </div>
      <div className="summary-chart">
        {combo_chart_options.map((chart_option, i) => {
          return (
            <div key={i} className="highcharts-figure">
              <div id="react-container">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chart_option}
                  allowChartUpdate={true}
                  //updateArgs={[true, true, true]}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="summary-chart">
        {stacked_column_bar_chart_options.map((chart_option, i) => {
          return (
            <div key={i} className="highcharts-figure">
              <div id="react-container">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chart_option}
                  allowChartUpdate={true}
                  //updateArgs={[true, true, true]}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Summary;
