import { Divider, FormControl, ListItemText,MenuItem, makeStyles, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import { CONFIG } from '../../helpers/config';
import './key-accounts.css'
import { abbreviateNumber } from '../../helpers/utils';


const useStyles = makeStyles((theme) => ({
  paperContainer: { overflowX: "auto" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180
  },
  menu: {
    width: 180,
    height: 35,
    background: '#EDE8FF',
    borderRadius: "12px",
    "& ul": {
      backgroundColor: "#cccccc",
    },
    "& li": {
      fontSize: 12,
    },
  },
  menuHistory: {
    width: 220,
    height: 35,
    background: '#FFF2E9',
    borderRadius: "12px"
  },
  settingFilter: {
    background: "#FFFFFF",
    border: '1px solid #E3E6EB',
    borderRadius: '40px',
  },
  listItem: {
    cursor: "pointer",
    fortFamily: 'momospace',
    fontSize: 'medium',
    whiteSpace: "normal",
  },
  root: {
    borderBottom: "none"
  }

}));

function KeyAccounts() {

  const classes = useStyles()

  const [user_name, setUserName] = useState('')
  const [table_data, setTableData] = useState(CONFIG.CATEGORY_SUMMARY_TABLE_DATA_2)
  const [selected_category_index, setSelectedCategoryIndex] = useState(-1)
  const [chart_data, setChartData] = useState(CONFIG.KEY_ACCOUNT_SUMMARY_CHART_DATA)
  const [combo_chart_options, setComboChartOptions] = useState([])
  const BU_filter_optionsValues2=CONFIG.BU_filter_optionsValues2
  const BU_filter_optionsValues3=CONFIG.BU_filter_optionsValues3
  const BU_filter_optionsValues4=CONFIG.BU_filter_optionsValues4
  const BU_filter_optionsValues5=CONFIG.BU_filter_optionsValues5

  const handleClick = (index) => {
    setSelectedCategoryIndex(index)
  }

  useEffect(() => {
    updateChartConfig()
  }, [])


  const comboChartData = (index) => {
    let data = [];
    chart_data.data.values[index].forEach((val, i) => {
      if (i !== 0) {
        data.push(val)
      }
    })
    return data;
  }

  const comboChartDataForImpact = () => {
    let data = [];
    chart_data.data.impact.forEach((val, i) => {
      if (i !== 0) {
        data.push(Number(val))
      }
    })
    return data;
  }

  const updateChartConfig = () => {
    let categories = []
    console.log(chart_data)
    chart_data.data.columns.forEach((col, i) => {
      if (i !== 0) {
        categories.push(col.slice(0, 3))
      }
    })
    let chart_options = comboChartConfig(categories);
    setComboChartOptions(chart_options)
  }

  const comboChartSeries = () => {
    let series = [];
    chart_data.data.values.forEach((val, i) => {
      series.push({
        name: chart_data.data.values[i][0],
        type: 'column',
        stack: 1,
        yAxis: 1,
        color: CONFIG.SEIRES_COLOR[i],
        data: comboChartData(i),
      })
    })

    series.push({
      name: chart_data.data.impact[0] === null ? 'Growth' : null,
      type: 'spline',
      color: CONFIG.SEIRES_COLOR[CONFIG.SEIRES_COLOR.length-1],
      data: comboChartDataForImpact(),
    })
    return series
  }

  const comboChartConfig = (categories) => {
    let chart_config_options = [];

    chart_config_options.push(({
      chart: {
        zoomType: 'xy',
        inverted: false
      },
      title: {
        text: "Month on Month Performance",
        align: 'left',
        x: 0,
        verticalAlign: 'top',
        y: 20,
        margin: 0,
        style: CONFIG.CHART_TITLE_STYLE,
      },
      subtitle: {
        text: 'Summary: Overall',
        align: 'left',
        x: 0,
        verticalAlign: 'top',
        // y: 0,
      },
      xAxis: [
        {
          categories: categories,
          crosshair: true,
          lineColor: 'transparent',
          lineWidth: 0,
        },
      ],
      yAxis: [{ // Primary yAxis
        visible: false,
      }, { // Secondary yAxis
        title: {
          text: '',
          style: {
            color: '#EBEDF0'
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: '#9FA2B4'
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      // tooltip: chartTooltip(_, abbreviation || this.state.abbreviation, formatting || this.state.formatting),
      // legend: chartLegend(metric_count, template_config, table_data),
      legend: {
        layout: 'horizontal',
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: -30,
        floating: false,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }, series: {
          borderRadius: 5
        }
      },
      // plotOptions: getPlotOptions(chart_config, _, _, abbreviation || this.state.abbreviation, formatting || this.state.formatting),
      series: comboChartSeries()
    }));
    return chart_config_options;
  }

  return <div className='summaries'>
    <div className='primary-header' style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
      <h1 className='header'>Key Accounts Summary</h1>
      <div className='user-data'>
        <div className='date-time'>
          <CalendarMonthIcon />
          <span style={{ fontFamily: "monospace" }}>&nbsp; Wednesday, 17 May 2021</span>
        </div>
        <div className='search-bell'>
          <SearchIcon style={{ color: '#C5C7CD' }} />
          <NotificationsIcon style={{ color: '#C5C7CD' }} />
        </div>
        <Divider orientation='vertical' />
        <h3 className='user-name'>{user_name}</h3>
        <AccountCircleIcon />
      </div>
    </div>
    <div className='setting-filters'>
    </div>
    <div className='header-row'>
      <div className='filters'>
        <h4 className='summary-history-heading'>
          Filters
        </h4>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={"HSF"}
            placeholder="MFT"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['HSF', 'National Accounts', 'WM'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues2[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Bodega Aurerra'}
            placeholder="Customer"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Bodega Aurerra', 'Calimax', 'Casa Ley'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues3[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'Algeria'}
            placeholder="Brand"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['Algeria', 'Bokados', 'Brownie', 'Choco Bara'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues4[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'12 oz'}
            placeholder="Cluster"
            disableUnderline
            className={classes.menu}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['12 oz', '18 oz', '24 oz'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={BU_filter_optionsValues5[filter]} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
      <div className='summary-history'>
        <h4 className='summary-history-heading'>
          Outlooks
        </h4>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={'12 oz'}
            placeholder="Cluster"
            disableUnderline
            className={classes.menuHistory}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
          >
            {['12 oz', '18 oz', '24 oz'].map((filter, i) => {
              return <MenuItem className={classes.listItem} key={i} value={filter}>
                <ListItemText primary={filter} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    </div>
    <div className='summary_table'>
      {table_data.map(t_d => {
        return <div className='table_data'>
          <Typography style={{ padding: "15px",cursor:selected_category_index !== -1 ?"pointer":"",fontFamily: 'Mulish'  }} onClick={() => handleClick(-1)} variant="h4" className={"MuiTypography--heading"} gutterBottom component="div">{selected_category_index !== -1 ? `${t_d.header}` + '\n > \n' + `${t_d.data[selected_category_index].category}` : t_d.header}</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {t_d.columns.map((col, i) => {
                    return <TableCell>{i === 0 ? <div style={{ display: "flex",fontFamily: 'Mulish' }}><h4 style={{ fontWeight: "lighter", color: '#9FA2B4' }}>Summary:&nbsp;</h4><h4 style={{ fontWeight: "bolder" }}>{`${t_d.summary}`}</h4></div> : col}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {selected_category_index === -1 ? t_d.data.map((dt, j) => {
                  return <TableRow>
                    <TableCell onClick={() => handleClick(j)} className='categories' style={{fontFamily: 'Mulish',fontWeight: 600,fontSize: "14px",  color: "#9FA2B4"}}>{dt.category}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(dt.last_year)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(dt.current_year)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(dt.baseline)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(dt.projection)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{dt.growth}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{dt.CAGR}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(dt.Revenue)}</TableCell>
                  </TableRow>
                }) : t_d.data[selected_category_index].data.map((d_t, k) => {
                  return <TableRow key={k}>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 600,fontSize: "14px",  color: "#252733"}}>{d_t.name}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(d_t.last_year)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(d_t.current_year)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(d_t.baseline)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(d_t.projection)}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{d_t.growth}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{d_t.CAGR}</TableCell>
                    <TableCell style={{fontFamily: 'Mulish',fontWeight: 400,fontSize: "14px", color: "#9FA2B4"}}>{abbreviateNumber(d_t.Revenue)}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      })}
    </div>
    <div className='summary-chart'>
      {combo_chart_options.map((chart_option, i) => {
        return (
          <div key={i} className="highcharts-figure">
            <div id="react-container">
              <HighchartsReact
                highcharts={Highcharts}
                options={chart_option}
                allowChartUpdate={true}
              //updateArgs={[true, true, true]}
              />
            </div>
          </div>
        );
      })}
    </div>
  </div>
}

export default KeyAccounts