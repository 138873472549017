import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react'
import { CONFIG } from '../../../helpers/config'
import './Scenario-admin.css'
import { Divider } from 'semantic-ui-react';

function ScenarioAdmin() {
  const [versions, setVersions] = useState(['Current Version', 'Existing Versions'])
  const [active_version_headers, setActiveVersionHeader] = useState('Current Version')
  const [cards_data, setCardsData] = useState(CONFIG.CARDS_DATA)
  const [active_scenario,setActiveScenario] = useState(-1)

  const handleChnage = (header) => {
    setActiveVersionHeader(header)
  }

  const updateActiveScenario = (sectionIndex) => {
    if(sectionIndex===active_scenario){
        setActiveScenario(-1)
    }else {
        setActiveScenario(sectionIndex)
    }
}

  return <>
    <div className='versions'>
      <div className='version-header'>
        {versions.map((h, i) => {
          return <div key={i} onClick={() => handleChnage(h)} className={active_version_headers === h ? 'versions-headers active-version-header' : 'versions-headers'}>
            <p style={{ margin: "auto" }}>{h}</p>
          </div>
        })}
      </div>
      <div style={{ display: "flex", justifyContent: "right", padding: "0 15px" }}>
        <Button className='create-new-buton' variant="contained">Create New Version</Button>
      </div>
      <div className='version-content'>
        <h3 className='text'>Repository for all scenarios that will come from Commercial intervention</h3>
        <p className='extra-text'>Current Baseline</p>
      </div>
    </div>
    <div className='cards'>
      {/* <Grid container spacing={2}> */}
      {cards_data.map((card, i) => {
        return <Accordion key={i} expanded={active_scenario===i} className='accordion'>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className='expand-icon' onClick={()=>updateActiveScenario(i)}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <div className='accordian-heading'>
              <div className='right-section'>
                <Typography variant="h5" style={{ fontWeight: 700, fontSize: '18px', color: "#252733", fontFamily: 'Mulish' }}>
                  {card.title}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400, fontSize: '12px', color: "#4B506D", fontFamily: 'Mulish' }}>
                  {card.sub_title}
                </Typography>
              </div>
              <div className='left-section'>
                <Typography variant="h6" style={{ fontWeight: 400, fontSize: '18px', color: "#9FA2B4", fontFamily: 'Mulish' }}>
                  Owner : &nbsp;
                </Typography>
                &nbsp;
                <Typography variant="h5" style={{ fontWeight: 700, fontSize: '18px', fontFamily: 'Mulish' }}>
                  {card.owner}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className='details-accordion'>
            <Divider/>
            {Object.entries(card.details).map(([key,value],i)=> {
              return <div className='accordion-data'>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 400, fontSize: '16px', color: "#9FA2B4", fontFamily: 'Mulish' }}>
                  {key}:&nbsp;
                </Typography>
                <Typography variant="h5" gutterBottom style={{ fontWeight: 700, fontSize: '16px', fontFamily: 'Mulish' }}>
                  {value}
                </Typography>
              </div>
            })}
          </AccordionDetails>
        </Accordion>
      })}
      {/* </Grid> */}
    </div>
  </>
}

export default ScenarioAdmin