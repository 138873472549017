import { TableContainer,Table, TableHead, TableRow, TableCell, TableBody, Switch, Typography } from '@material-ui/core'
import React from 'react'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './TableStructure.css'
import { useState } from 'react';
import TableRowDataModal from '../TableRowDataModal/TableRowDataModal';

function TableStructure({table_data}) {

  const [selected_table_row,setSelectedTableRow] = useState(-1)

  const handleClickOnTableRow = (table_row) =>{
    setSelectedTableRow(table_row)
  }

  const handleClose = () => {
    setSelectedTableRow(-1)
  }

  const quickkActions = ()=>{
    return <div className='quik-actions'>
      <AddCircleOutlineRoundedIcon className='add-action'/>
      <VisibilityOutlinedIcon className='view-action'/>
      <DeleteOutlineIcon className='delete-action'/>
    </div>
  }

  return (
    <div className='table_structure'>
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {table_data.columns.map((col,i)=>{
           return <TableCell align="center" key={i}><Typography variant="h6" style={{ fontWeight: "bold", borderBottom: "none",fontSize:"14px", fontFamily: 'Mulish' }} gutterBottom component="div">{col}</Typography></TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
        {table_data.data.map((dat,j)=>{
          return <TableRow className={selected_table_row===j?'selected-table-row':'table-row'} key={j}>
            <TableCell onClick={()=>handleClickOnTableRow(j)} align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>{dat.version}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>{dat.owner}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>{dat.volume}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>{dat.trade_spend}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>Final&nbsp;<Switch className='switch-final-test' checked={dat.final_test==='Test'}/>&nbsp;Test</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold",fontSize:"14px", fontFamily: 'Mulish' }}>{quickkActions()}</TableCell>
          </TableRow>
        })}
         
        </TableBody>
      </Table>
    </TableContainer>
    {selected_table_row!==-1?<TableRowDataModal table_data={table_data.data[selected_table_row]} open={true} close={handleClose}/>:null}
    </div>
  )
}

export default TableStructure