import { CONFIG } from "./config";

export function abbreviateNumber(num, fixed=1) {
    if (num === null || num === undefined) { return null; } // terminate early
    if (isNaN(num) || num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(fixed), // divide by power
      d = Number(c) < 0 ? c : Math.abs(Number(c)), // enforce -0 is 0
      abbr = Number(d).toFixed(fixed) + CONFIG.NUMBER_SUFFIX[k];
    return abbr;
  }

  export const getCookie = (name) => {
      const ca = decodeURIComponent(document.cookie).split(";");
      const caLen = ca.length;
      const cookieName = `${name}=`;
      let c;
    
      for (let i = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, "");
        if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
      return "";
    };