import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import ReplyAllRoundedIcon from '@mui/icons-material/ReplyAllRounded';
import TouchAppTwoToneIcon from '@mui/icons-material/TouchAppTwoTone';
import './Strategic-pricing.css'
import TableStructure from '../../common/TableStructure/TableStructure';
import { CONFIG } from '../../../helpers/config'
import { useEffect } from 'react';

function StrategicPricing() {
  const [table_data, setTableData] = useState({})

  const fetchTableData = () => {
    setTableData(CONFIG.STRATEGIC_PRICE_TABLE)
  }

  const checkForData = () =>{
    console.log(table_data)
    let data_available = false
    if(Object.keys(table_data).length>0){
      data_available = true
    }
    console.log(data_available)
    return data_available
  }
  return (
    <div className='startegic-pricing'>
      <div className='headers'>
        <Typography variant="h6" gutterBottom component="div" style={{fontWeight: 500,fontSize: '19px'}}>
          All Scenarios
        </Typography>
        <div className='action-buttons'>
          <Button className={checkForData() ? 'functionality-buttons active-button' : 'functionality-buttons'} variant="contained" style={{ marginRight: "30px" }} onClick={fetchTableData} startIcon={<TouchAppTwoToneIcon />}>Fetch Scenario</Button>
          <Button className='functionality-buttons' variant="contained" startIcon={<ReplyAllRoundedIcon />}>Go to Tools</Button>
        </div>
      </div>
      {checkForData() ? <TableStructure table_data={table_data}/>: <div className='no-data'>
        {/* <Typography variant="h3" gutterBottom component="div" style={{ color: "#928585",fontWeight: "600",fontSize: '30px' }}>
          No Data
        </Typography> */}
        <Typography variant="h6" gutterBottom component="div" style={{ color: "#C46363",fontWeight: "400",fontSize: '21px'  }}>
          Fetch Scenarios by linking tool or manually create new version
          by uploading data template
        </Typography>
      </div>}
    </div>
  )
}

export default StrategicPricing